<template>
  <div
    class="login-container bg-ease d-flex align-items-center justify-content-center"
  >
    <v-form
      ref="form"
      v-model="isValid"
      class="form-container pa-9"
      @submit.prevent
    >
      <v-row no-gutters>
        <v-col class="logo">
          <img
            class="img-fluid"
            src="@/assets/images/logo.svg"
          >
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="mb-9"
      >
        <v-col>
          <div class="text-center text-h4 font-weight-medium white--text">
            {{ $t('reset_password') }}
          </div>
        </v-col>
      </v-row>
      <v-row
        align="center"
        no-gutters
      >
        <v-col cols="12">
          <v-text-field
            v-model="password1"
            solo
            dark
            autofocus
            background-color="rgb(197,213,201)"
            :label="$t('new_password')"
            :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPwd ? 'text' : 'password'"
            :rules="[validateRequired]"
            @click:append="showPwd = !showPwd"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="password2"
            solo
            dark
            background-color="rgb(197,213,201)"
            :label="$t('re_password')"
            :append-icon="showPwd ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPwd ? 'text' : 'password'"
            :rules="[validateRequired]"
            @click:append="showPwd = !showPwd"
          />
        </v-col>
      </v-row>
      <v-row
        class="mt-5"
        justify="center"
        align="center"
        no-gutters
      >
        <v-col
          cols="8"
          class="text-center"
        >
          <v-btn
            class="font-weight-bold text-h6 primary--text"
            rounded
            type="submit"
            :disabled="!isValid"
            :loading="isLoading"
            style="width: 100%; height: 50px;"
            @click="submit"
          >
            {{ $t('reset_password') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        class="mt-3"
        justify="center"
        align="center"
        no-gutters
      >
        <router-link
          class="link"
          :to="{ name: 'Login' }"
        >
          {{ $t('back_to_login') }}
        </router-link>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { validateRequired } from '@/assets/js/validate'

import { apiCommonauthPasswords } from '@/api'

export default {
  name: 'ForgoResetPwdtPwd',

  data: () => ({
    isLoading: false,
    isValid: false,
    password1: '',
    password2: '',
    showPwd: false,
    validateRequired
  }),

  computed: {
    code() {
      return this.$route.params.code
    }
  },

  watch: {
    '$route.params.lang'(newVal, oldVal) {
      if (newVal !== oldVal && this.$refs.form) {
        this.$refs.form.validate()
      }
    }
  },

  mounted() {
    if (!this.code && this.$route.name !== 'Login') {
      this.$router.push({ name: 'Login' })
    }
  },

  methods: {
    async submit() {
      await this.$refs.form.validate()

      if (!this.isValid || !this.code) {
        return
      }

      if (this.password1 !== this.password2) {
        this.$store.dispatch('snackbar/showError', {
          content: this.$t('pwd_not_match')
        })
        return
      }

      this.isLoading = true
      return apiCommonauthPasswords.post({
        password: this.password1,
        code: this.code
      })
        .then(() => {
          this.$store.dispatch('snackbar/showSuccess', {
            content: this.$t('page.reset_pwd.success')
          })

          if (this.$route.name !== 'Login') {
            this.$router.push({
              name: 'Login'
            })
          }
        })
        .catch(error => {
          this.$store.dispatch('snackbar/showError', {
            content: error
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .form-container {
    width: 29%;
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 10px;

    .v-input.v-text-field {
      border-radius: 6px;

      ::v-deep .v-input__slot {
        background-color: rgba(234, 234, 234, 0.5);

        .v-text-field__slot {
          .v-label,
          input {
            color: white;
          }
        }
      }
    }
  }

  .logo {
    text-align: center;
    border-style: solid;
    border-width: 0;
    border-color: white;
  }

  .v-btn:not(.v-btn--round).v-size--default {
    padding: 0 5px;
    min-width: 29px;
  }

  .link {
    text-decoration: none;

    &:link {
      color: white;
    }

    &:visited {
      color: white;
    }

    &:hover {
      color: #36b;
    }

    &:active {
      color: #f00;
    }
  }
}

@media screen and (max-width: 1366px) and (min-width: 1025px) {
  .login-container {
    .form-container {
      width: 50%;
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 769px) {
  .login-container {
    .form-container {
      width: 69%;
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 415px) {
  .login-container {
    .form-container {
      width: 95%;
    }
  }
}

@media screen and (max-width: 414px) {
  .login-container {
    .form-container {
      width: 100%;
    }
  }
}
</style>
